ul {
  display: flex;
  list-style: none;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
  margin-top: 1em;
}
ul a:hover {
  color: var(--d);
}
ul a {
  background-color: var(--a);
  color: var(--c);
  font-size: 1.25rem;
  margin-right: 1em;
  text-decoration: none;
  padding: 0.5em;
  border-radius: 5px;
}
@media (max-width: 650px) {
  ul {
    width: 100%;
    margin: 2em auto;
  }
  ul li {
    width: 100px;
    font-size: 1rem;
    margin-right: 0;
  }
}
footer {
  text-align: center;
  margin: auto;
  bottom: 0;
  width: 100%;
  /* height: 60px; Height of the footer */
}
footer a {
  text-decoration: none;
  color: #ff5f5f;
  font-weight: 800;
}
footer a:hover {
  /* transform: scale(1.1); */
  font-size: 1.05rem;
  transition: all 0.5s ease-in-out;
}
