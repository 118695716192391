.post-card {
  width: 500px;
  background-color: var(--a);
  border-radius: 10px;
  box-shadow: 0 2px 4px #0f0f0f;
  padding: 1em;
  margin: 2em auto;
  cursor: pointer;
}
.post-card:hover {
  transform: scale(1.05);
  transition: all 0.5s ease-in-out;
}
.post-username {
  border-bottom: 1px solid var(--c);
  padding-bottom: 1em;
}
.post-card h2 {
  color: var(--c);
}
.posts-header {
  color: var(--d);
  text-align: center;
}
.error {
  text-align: center;
  color: var(--c);
  margin-bottom: 5em;
}
.error a {
  text-decoration: none;
  padding: 0.25em;
  color: var(--d);
  font-weight: 800;
  border: none;
  width: 150px;
  border-radius: 5px;
}
.error a:hover {
  background-color: var(--d);
  color: var(--a);
  cursor: pointer;
  box-shadow: 0 0 2px var(--c);
}

@media (max-width: 650px) {
  * {
    padding: 0;
    margin: 0;
  }
  .post-card {
    width: 90%;
  }
  .post-username {
    margin-top: 0.5em;
    margin-bottom: 1em;
  }
}
