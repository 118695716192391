.hero {
  background-color: var(--a);
  width: fit-content;
  padding: 2em;
  border-radius: 10px;
  margin: 2em auto;
  display: flex;
  flex-direction: column;
}
.hero img {
  width: 100%;
  max-width: 750px;
}
.hero h1 {
  color: var(--c);
  text-shadow: 0 0 3px;
  cursor: pointer;
  margin-bottom: 0;
}
.hero p {
  margin-top: 10;
}
.hero a {
  text-decoration: none;
  color: #f48120;
  font-weight: 800;
  text-shadow: 0 0 2px;
}

.hero-buttons {
  margin: auto;
  margin-top: 1em;
}
.hero-buttons button {
  padding: 0.5em;
  background-color: var(--c);
  color: var(--d);
  font-weight: 800;
  border: none;
  margin-left: 1em;
  width: 150px;
  border-radius: 5px;
  border: 2px solid var(--c);
}
.hero-buttons button:hover {
  background-color: var(--d);
  color: var(--a);
  cursor: pointer;
  box-shadow: 0 0 2px var(--c);
  border: 2px solid var(--d);
}
@media (max-width: 600px) {
  .hero {
    width: 95%;
  }
  .hero-buttons button {
    width: 100px;
  }
  .hero h1 {
    margin-bottom: 0.5em;
  }
  .hero p {
    margin-bottom: 1em;
  }
}
