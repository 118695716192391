.create-post form {
  display: flex;
  flex-direction: column;
  width: 500px;
  margin: auto;
}
.create-post input,
.create-post textarea,
.create-post button {
  margin-top: 1em;
  background-color: var(--a);
  color: var(--c);
  border: none;
  font-size: 1.25rem;
  padding: 0.5em;
  border-radius: 5px;
}
.create-post button {
  width: fit-content;
  margin: 1em auto;
  color: var(--c);
  font-weight: 800px;
  border: none;
  width: 150px;
}
.create-post button:hover {
  cursor: pointer;
  background-color: var(--c);
  color: var(--a);
}
.create-post textarea {
  height: 200px;
}
.create-post h1 {
  text-align: center;
  color: var(--c);
}
@media (max-width: 650px) {
  .create-post form {
    width: 90%;
  }
}
